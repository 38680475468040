.header {
  position: absolute;
  width: 100%;
  height: 100px;
  a {
    z-index: 10;
    position: absolute;
    top: 30px;
    left: 10px;
  }
  svg {
    height: 40px;
    width: 200px;
  }
}
.top {
  background: #000;
  text-align:center;
  video {
    z-index: 1;
    width: 100%;
  }
  p.main-catch-copy {
    font-family: Roboto;
    font-weight: bold;      
    color: #fff;
    position: absolute;
    font-size: 6vw;
    left: 15%;
    top: 35%;
    z-index: 3;
  }
}
.mobile-top {
  background: #000;
  text-align:center;
  video {
    z-index: 1;
    width: 100%;
  }
  p.main-catch-copy {
    font-family: Roboto;
    font-weight: bold;      
    color: #fff;
    position: absolute;
    font-size: 7vw;
    left: 15%;
    top: 70%;
    z-index: 3;
  }
}